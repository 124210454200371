import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

export default () => {
  // data
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)

  // handlers
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const addForm = formHandler()
  const editForm = formHandler()

  const fields = ref([
    {
      label: 'Object ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Description',
      key: 'description',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Type',
      key: 'type',
      type: 'select',
      options: selectValues.connector_types,
      default: 'Ealyze',
      searchable: true,
    },
    {
      label: 'API Key',
      key: 'api_key',
      type: 'string',
      table: false,
      dependent_show_values: ['Ealyze', 'SimpleWEG IoT'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
    {
      label: 'Username',
      key: 'username',
      type: 'string',
      dependent_show_values: ['B One Middleware'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      searchable: true,
    },
    {
      label: 'Password',
      key: 'password',
      type: 'string',
      table: false,
      dependent_show_values: ['B One Middleware'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
    {
      label: 'Tenant',
      key: 'tenant',
      type: 'string',
      dependent_show_values: ['B One Middleware'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      searchable: true,
    },
    {
      label: 'Client ID',
      key: 'client_id',
      type: 'string',
      dependent_show_values: ['SmartDodos'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
      searchable: true,
    },
    {
      label: 'Client secret',
      key: 'client_secret',
      type: 'string',
      table: false,
      dependent_show_values: ['SmartDodos'],
      dependent_show_field_key: 'type',
      dependent_show_field_type: 'select',
    },
  ])

  // list
  function getRecords() {
    loadHandler.setLoadingState('get_records', true)

    useApi.requestV2('get', 'v1/connectors/').then((response) => {
      records.value = response.data
      loadHandler.setLoadingState('get_records', false)
    })
  }

  // add
  function addRecord() {
    const payload = addForm.data.value
    loadHandler.setLoadingState('add_record', true)
    useApi.requestV2('post', 'v1/connectors/', payload).then(() => {
      loadHandler.setLoadingState('add_record', false)
      showAdd.value = false
      getRecords()
    })
  }

  // open edit
  function openEdit(data) {
    editForm.create({ type: 'edit', fields: fields.value, data: data })
    showEdit.value = true
  }

  function openAdd() {
    addForm.create({ type: 'add', fields: fields.value, data: {} })
    showAdd.value = true
  }

  function closeAdd() {
    addForm.data.value = {}
    showAdd.value = false
  }

  function closeEdit() {
    editForm.data.value = {}
    showEdit.value = false
  }

  // edit
  function editRecord() {
    const payload = editForm.data.value
    const object_id = payload.object_id

    loadHandler.setLoadingState('edit_record', true)
    useApi.requestV2('patch', `v1/connectors/${object_id}`, payload).then(() => {
      loadHandler.setLoadingState('edit_record', false)
      closeEdit()
      getRecords()
    })
  }

  // delete
  function deleteRecord(value) {
    loadHandler.setLoadingState('delete_record', true)
    useApi.requestV2('delete', `v1/connectors/${value}`).then(() => {
      loadHandler.setLoadingState('delete_record', false)
      getRecords()
    })
  }

  return {
    records,
    getRecords,
    addRecord,
    fields,
    addForm,
    editForm,
    showAdd,
    showEdit,
    openEdit,
    openAdd,
    editRecord,
    deleteRecord,
    loadHandler,
    closeAdd,
    closeEdit,
  }
}
